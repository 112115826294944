.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  min-width: 720px;
  background: #1C1C21;
  border: 1px solid rgba(76, 76, 255, 0.2);
  box-sizing: border-box;
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04), 0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  color: #F2F2F3;
}

.close {
  cursor: pointer;
  position: absolute;
  display: flex;
  top: 60px;
  right: 40px;
}

.wrapper {
  max-width: 584px;
  max-height: 726px;
  height: 100%;
  min-height: 726px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.constructor_wrapper {
  max-width: 568px;
  max-height: 656px;
  min-height: 726px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.items {
  max-width: 568px;
  max-height: 496px;
  overflow-y: auto;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.item {
  display: flex;
  align-items: center;
  cursor: grabbing;
}

.order {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.price {
  display: flex;
  align-items: center;

}

.item_empty {
  padding: 26px 34px;
  background: #2f2f37;
  width: 488px;
  text-align: center;
  color: #F2F2F3;
}

.item_empty_top {
  border-radius: 88px 88px 40px 40px;
  margin-bottom: 8px;
}

.item_empty_bottom {
  border-radius: 40px 40px 88px 88px;
  margin-top: 8px;
}

.item_empty_middle {
  border-radius: 40px 40px 40px 40px;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 85%;
}
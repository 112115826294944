.spinner {
  width: 1em;
  height: 1em;
  margin: 0 auto;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
}



.spinner i::after {
  position: absolute;
  clip: rect(0, 1em, 1em, 0.5em);
  width: 1em;
  height: 1em;
  content: '';
  animation: spinner-circle 1.5s ease-in-out infinite;
  border-radius: 50%;
  /* box-shadow: inset 0 0 0 0.1em black; */
  box-shadow: inset 0 0 0 0.1em#762D76;
}

@keyframes spinner-circle {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.spinner__button {
  font-size: 20px;
  left: 0;
  top: calc(50% - 10px);

}

.spinner__button i::after {
  //box-shadow: inset 0 0 0 0.1em#1c1c21;
  animation: spinner-circle 2s linear infinite;
}

.spinner__root {
  font-size: 50px;
  left: calc(50% - 25px);
}
@mixin position_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  text-align: center;
  position: relative;
  width: 272px;
  text-decoration: none;
  color: #F2F2F3;

  @include position_center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }
}

.price {
  @include position_center;
}

.card {
  position: relative;
  width: 272px;
  max-height: 208px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #F2F2F3;
}

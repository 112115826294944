.list_of_done {
  margin-bottom: 8px;
  color: #00CCCC;
  list-style-type: none;
}

.list_of_created {
  margin-bottom: 8px;
  color: #F2F2F3;
  list-style-type: none;
}






.scroll {
  scrollbar-color: #2F2F37 #8585AD;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #2F2F37;
  }

  &::-webkit-scrollbar-thumb {
    background: #8585AD;
    border: 3px solid #2F2F37;
    border-radius: 6px;
  }

}




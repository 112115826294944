.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100%;
}

.title {
  position: absolute;
  z-index: 2;
}

.img {
  width: 600px;
  height: 600px;
  opacity: .3;
  border-radius: 50%;
}
.wrapper {
  width: 600px;
  max-height: 512px;
  position: relative;
}

.ingredients {
  width: 600px;
  max-height: 656px;
  overflow-y: auto;
}


.cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

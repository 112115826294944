.link {
  text-decoration: none;
  color: #F2F2F3;
}

.card_wrapper {
  padding: 24px;
  background: #1C1C21;
  border-radius: 40px;
}

.info_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ingredients_wrapper {
  width: 345px;
  overflow: hidden;
  height: 68px;
  display: flex;
}

.ingredients_wrapper :first-child {
  z-index: 10;
}

.ingredients_wrapper :nth-child(2) {
  z-index: 9;
}

.ingredients_wrapper :nth-child(3) {
  z-index: 8;
}

.ingredients_wrapper :nth-child(4) {
  z-index: 7;
}

.ingredients_wrapper :nth-child(5) {
  z-index: 6;
}

.ingredients_wrapper :nth-child(6) {
  z-index: 5;
}

.ingredients_opacity {
  opacity: .6;
}

.round {
  position: relative;
  margin-right: -16px;
  width: 64px;
  height: 64px;
  background: #131316;
  border-radius: 100%;
  border: 2px solid rgba(128, 26, 178, 1);
}

.round_add2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.round_add {
  position: relative;
}

.round img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.round_modern {
  position: relative;
  width: 64px;
  height: 64px;
  background: #131316;
  border-radius: 100%;
  border: 2px solid rgba(128, 26, 178, 1);
}

.round_img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180%;
  height: auto;
}

.wrapper {
  display: flex;
  align-items: center;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}

.desc_block {
  display: flex;
  align-items: center;
}

.price_block {
  display: flex;
  align-items: center;
}


.img {
  margin-left: -40px;
}
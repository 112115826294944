.img {
  margin: 0 auto;
  width: 480px;
  height: 240px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #8585AD;
}

.desc {
  min-width: 112px;
  height: 56px;
  text-align: center;
}


.wrapper {
  max-height: 400px;
  overflow: auto;
}

.header {
  max-width: 630px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
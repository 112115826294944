$headerBg: #1c1c21;
$clearColor: #F2F2F3;
$muddyColor: #8585AD;

.header {
  background: $headerBg;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04),
  0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04),
  0 0 1px rgba(0, 0, 0, 0.04);
}

.user_options {
  display: flex;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}


.item {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $clearColor;

  &_muddy {
    color: $muddyColor;
  }
}


.logo {
  position: absolute;
  left: calc(50% - 145px);
  top: calc(50% - 27px);
}


